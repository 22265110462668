<template>
  <div>
    <div v-if="!isLoading">
      <div class="flex align-items-center justify-end mb-8 gap-8 flex-col-sm">
        <input v-model="keyword" aria-label="search" class="form-control" placeholder="Search..." style="width: 250px"
          type="text" @keyup="searchThreeChar($event)" @keydown="searchOnPaste($event)" @keydown.enter="search()" />
      </div>
      <div class="table-responsive">
        <table class="table table-condensed table-striped">
          <thead>
            <tr>
              <th class="pointer" @click="sort('ID')">
                ID
                <span v-if="sortKey === 'ID'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer" @click="sort('RegisteredEmail')">
                Registered Email
                <span v-if="sortKey === 'RegisteredEmail'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer" @click="sort('XChangeNameID')">
                XChange Name
                <span v-if="sortKey === 'XChangeNameID'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer" @click="sort('XChangeServer')">
                XChange Server
                <span v-if="sortKey === 'XChangeServer'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer" @click="sort('XChangeSecretKey')">
                XChange Secret Key
                <span v-if="sortKey === 'XChangeSecretKey'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer" @click="sort('XChangeAPIKey')">
                XChange API Key
                <span v-if="sortKey === 'XChangeAPIKey'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer" @click="sort('KcCoinBrokerUid')">
                Broker Uid
                <span v-if="sortKey === 'KcCoinBrokerUid'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer" @click="sort('IsActive')">
                Active
                <span v-if="sortKey === 'IsActive'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer">
                Is In Use
              </th>
              <th class="pointer" @click="sort('CreatedOn')">
                Created On
                <span v-if="sortKey === 'CreatedOn'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
              <th class="pointer" @click="sort('UpdatedOn')">
                Updated On
                <span v-if="sortKey === 'UpdatedOn'" class="ml-4">
                  <i class="fa fa-sort"></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="records.length > 0">
              <tr v-for="record in records" :key="record.ID">
                <td>
                  <div class="flex align-items-center">
                    <span class="mr-4">{{ record.ID }}</span>
                    <div class="flex" v-if="record.XChangeName.toLowerCase() === 'kucoin'">
                      <button class="btn btn-primary btn-flat btn-sm mr-4"
                        @click="launchDataModel(record, 'balance')">Balance</button>
                      <button class="btn btn-flat btn-primary btn-sm mr-4" @click="launchDataModel(record, 'deposits')">
                        Deposit List</button>
                      <button class="btn btn-flat btn-primary btn-sm mr-4"
                        @click="launchDataModel(record, 'withdrawals')">Widthdrawal List</button>
                      <button class="btn btn-flat btn-primary btn-sm"
                        @click="launchDataModel(record, 'ledgers')">Ledgers</button>
                    </div>
                  </div>
                </td>
                <td class="no-wrap">{{ record.RegisteredEmail }}</td>
                <td>{{ record.XChangeName }}</td>
                <td>{{ record.XChangeServer }}</td>
                <td>{{ record.XChangeSecretKey }}</td>
                <td>{{ record.XChangeAPIKey }}</td>
                <td>{{ record.kc_broker_uid }}</td>
                <td>
                  <span v-html="formatStatus(record.isActive, 'isActive')"></span>
                </td>
                <td>
                  <span v-if="record.bot" class="no-wrap">
                    {{ record.bot.ID + ' - ' + record.bot.Symbol + ' - ' + record.bot.MerchantID }}
                  </span>
                </td>
                <td class="no-wrap">{{ record.CreatedOn }}</td>
                <td class="no-wrap">{{ record.UpdatedOn }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="11" class="text-center">No record found!</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div v-if="meta && meta.total > 0">
        <pagination :meta="meta" @pageChange="updatePage"></pagination>
      </div>
    </div>
    <div v-else>
      <Loader />
    </div>
    <!-- KuCoin Data Modal -->
    <KuCoinDataModal></KuCoinDataModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loader from "@/components/partials/Loader";
import Pagination from "@/components/Pagination";
import KuCoinDataModal from "@/components/modals/KuCoinDataModal";

export default {
  name: "SubBrokerAccounts",
  components: {
    Loader,
    Pagination,
    KuCoinDataModal,
  },
  data() {
    return {
      keyword: "",
      sortKey: "ID",
      order: "desc",
      isLoading: false,
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      records: [],
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "jwtToken",
    ]),
  },
  watch: {
    $route: {
      handler(e) {
        const url = new URL(window.location.href.replace("#", ""));
        const searchKeyword = url.searchParams.get("keyword");
        if (searchKeyword !== null) {
          this.keyword = searchKeyword;
        }
      },
      immediate: true,
    },
  },
  methods: {
    updatePage(page) {
      this.getPaginatedTradeBotSubBrokerAccounts(page);
    },
    searchOnPaste(e) {
      if (e.code === "KeyV" && e.ctrlKey) {
        setTimeout(() => {
          this.search();
        }, 50);
      }
    },
    searchThreeChar(e) {
      if (this.keyword.trim().length >= 3) {
        setTimeout(() => {
          this.search();
        }, 50);
      } else if (this.keyword.trim() === "") {
        this.search();
      }
    },
    search() {
      this.getPaginatedTradeBotSubBrokerAccounts(1, false);
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.getPaginatedTradeBotSubBrokerAccounts(1, false);
    },
    getPaginatedTradeBotSubBrokerAccounts(page = 1, isLoader = true) {
      if (isLoader) {
        this.isLoading = true;
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListSubBrokerBotsAccounts`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          limit: this.meta.limit,
          order: this.sortKey,
          orderBy: this.order,
          keyword: this.keyword ? this.keyword.toString().trim() : "",
        },
      }).then(response => {
        this.isLoading = false;
        this.meta = response.data.data.meta;
        this.records = response.data.data.records;
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("", error.data.message, "error");
      });
    },
    generateStatusLabel(key, status) {
      let label = "";
      switch (key) {
        case "isActive":
          label = status ? "Yes" : "No";
          break;
      }
      return label;
    },
    generateStatusClass(key, status) {
      let lblClass = "";
      switch (key) {
        case "isActive":
          lblClass = status ? "label-success" : "label-danger";
          break;
      }
      return lblClass;
    },
    formatStatus(status, key) {
      const formattedStatus = this.generateStatusLabel(key, status);
      const lblClass = this.generateStatusClass(key, status);
      return "<label class=\"label " + lblClass + " pointer\">" + formattedStatus + "</label>";
    },
    launchDataModel(record, action) {
      this.$bus.$emit("KuCoinDataModal", {
        record,
        action,
        currency: null,
      });
    },
  },
  mounted() {
    this.getPaginatedTradeBotSubBrokerAccounts();
  },
};
</script>
