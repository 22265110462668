<template>
  <div>
    <div v-if="!isLoading">
      <div class="flex align-items-center justify-end mb-8 gap-8 flex-col-sm">
        <select v-model="algo" class="form-control" style="width: 200px">
          <option value="">All Algorithms</option>
          <option value="DayTrading">DayTrading</option>
          <option value="MovingAverageCrossover">MovingAverageCrossover</option>
        </select>
        <div class="dropdown ml-8">
          <a class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
            {{ statuses.filter(s => s.selected).length }} options selected
          </a>
          <div class="dropdown-menu">
            <form>
              <a class="dropdown-item" href="#">
                <label class="d-block">
                  <input type="checkbox" class="form-checkbox" v-model="allStatuses" :checked="allStatuses" />
                  <span class="ml-3">Select All</span>
                </label>
              </a>
              <a class="dropdown-item" href="#" v-for="(status, index) in statuses" :key="index">
                <label class="d-block">
                  <input type="checkbox" class="form-checkbox" v-model="status.selected" :checked="status.selected" />
                  <span class="ml-3">{{ status.label }}</span>
                </label>
              </a>
            </form>
          </div>
        </div>
        <input v-model="keyword" aria-label="search" class="form-control" placeholder="Search..." style="width: 250px"
          type="text" @keyup="searchThreeChar($event)" @keydown="searchOnPaste($event)" @keydown.enter="search()" />
        <router-link :to="{ name: 'spawn-bot' }" class="btn btn-primary btn-flat btn-sm">Add New Bot</router-link>
      </div>
      <div class="table-responsive">
        <table class="table table-condensed table-striped">
          <thead>
            <tr>
              <th></th>
              <th>ID</th>
              <th>Name</th>
              <th>Merchant</th>
              <th>Sub Broker Account</th>
              <th>Actions</th>
              <th>Symbol</th>
              <th>Base Currency Balance</th>
              <th>Quote Currency Balance</th>
              <th>Total Initial Value</th>
              <th>Final Base Currency Balance</th>
              <th>Final Quote Currency Balance</th>
              <th>Total Final Value</th>
              <th>Difference</th>
              <th>Profit %</th>
              <th>Initial %</th>
              <th>Trade Trigger %</th>
              <th>Abort Condition</th>
              <th>Price</th>
              <th>Last Bid</th>
              <th>Last Ask</th>
              <th>Algo</th>
              <th>Risk Profile</th>
              <th>Segments</th>
              <th>Status</th>
              <th>Order Type</th>
              <th>Immediate</th>
              <th>Created On</th>
              <th>Updated On</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="records.length">
              <template v-for="(record, index) in records">
                <tr :key="record.ID">
                  <td>
                    <span class="flex pointer text-success" @click="record.isExpanded = !record.isExpanded">
                      <svg v-if="!record.isExpanded" class="w-24 h-24" fill="none" stroke="currentColor"
                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="2"></path>
                      </svg>
                      <svg v-if="record.isExpanded" class="w-24 h-24" fill="none" stroke="currentColor"
                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="2"></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <router-link :to="{ name: 'spawn-bot', query: { bot: record.ID, merchant: record.MerchantID } }">
                      {{ record.ID }}
                    </router-link>
                  </td>
                  <td>{{ record.Name }}</td>
                  <td class="no-wrap">
                    <router-link :to="{ name: 'user-detail', params: { id: record.MerchantID } }">
                      {{ record.MerchantID + ' - ' + record.MerchantName }}
                    </router-link>
                  </td>
                  <td>
                    <router-link
                      :to="{ name: 'trade-bots', hash: '#sub-borker-accounts?keyword=' + record.SubBrokerAccountID }">
                      {{ record.SubBrokerAccountID }}
                    </router-link>
                  </td>
                  <td>{{ record.Actions }}</td>
                  <td class="no-wrap">{{ record.Symbol }}</td>
                  <td class="no-wrap">{{ record.InitialBaseAmount + ' ' + record.BaseCurrencyLongName }}</td>
                  <td class="no-wrap">{{ record.InitialQuoteAmount + ' ' + record.QuoteCurrencyLongName }}</td>
                  <td class="no-wrap">
                    {{ ((record.InitialBaseAmount * record.Price) + record.InitialQuoteAmount).toFixed(8) + ' ' +
      record.QuoteCurrencyLongName }}
                  </td>
                  <td class="no-wrap">{{ record.FinalBaseAmount + ' ' + record.BaseCurrencyLongName }}</td>
                  <td class="no-wrap">{{ record.FinalQuoteAmount + ' ' + record.QuoteCurrencyLongName }}</td>
                  <td class="no-wrap">
                    <span v-if="record.LastBid">
                      {{ ((record.FinalBaseAmount * record.LastBid) + record.FinalQuoteAmount).toFixed(8) + ' ' +
      record.QuoteCurrencyLongName }}
                    </span>
                  </td>
                  <td class="no-wrap">
                    <span v-if="record.LastBid">
                      {{ (((record.FinalBaseAmount * record.LastBid) + record.FinalQuoteAmount) -
      ((record.InitialBaseAmount * record.Price) + record.InitialQuoteAmount)).toFixed(8) + ' ' +
      record.QuoteCurrencyLongName }}
                    </span>
                  </td>
                  <td class="no-wrap">{{ record.ProfitPercentage + '%' }}</td>
                  <td class="no-wrap">{{ record.InitialPercentage + '%' }}</td>
                  <td class="no-wrap">{{ record.TradeTrigger + '%' }}</td>
                  <td class="no-wrap">
                    {{ record.AbortCondition + (record.AbortCondition != 'none' ? '% loss' : '') }}
                  </td>
                  <td class="no-wrap">{{ record.Price + ' ' + record.QuoteCurrencyLongName }}</td>
                  <td class="no-wrap">
                    <span v-if="record.LastBid">
                      {{ record.LastBid + ' ' + record.QuoteCurrencyLongName }}
                    </span>
                  </td>
                  <td class="no-wrap">
                    <span v-if="record.LastAsk">
                      {{ record.LastAsk + ' ' + record.QuoteCurrencyLongName }}
                    </span>
                  </td>
                  <td class="no-wrap">{{ record.Algo }}</td>
                  <td class="no-wrap">{{ record.RiskProfile }}</td>
                  <td class="no-wrap">{{ record.Segments }}</td>
                  <td class="no-wrap">
                    <span>{{ record.Status }}</span>
                  </td>
                  <td class="no-wrap">{{ record.OrderType }}</td>
                  <td class="no-wrap">
                    <span v-html="formatValue(record.Immediate, 'Immediate')"></span>
                  </td>
                  <td class="no-wrap">{{ record.CreatedOn }}</td>
                  <td class="no-wrap">{{ record.UpdatedOn }}</td>
                  <td class="no-wrap">
                    <button class="btn btn-danger btn-flat btn-sm ml-8"
                      v-if="record.Status === 'running' || record.Status === 'pending'" @click="cancelBot(record)">
                      Cancel
                    </button>
                    <button type="button" v-if="record.Status === 'running'" class="btn btn-danger btn-flat btn-sm ml-8"
                      @click="pauseBot(record)">
                      Pause
                    </button>
                    <button type="button" v-if="record.Status === 'paused'" class="btn btn-primary btn-flat btn-sm ml-8"
                      @click="resumeBot(record)">
                      Resume
                    </button>
                  </td>
                </tr>
                <template v-if="record.isExpanded">
                  <tr :key="'detail_' + record.ID">
                    <td colspan="19">
                      <table class="table table-condensed table-striped">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Order ID</th>
                            <th>Base Currency</th>
                            <th>Quote Currency</th>
                            <th>Action</th>
                            <th>Amount</th>
                            <th>Initial Price</th>
                            <th>Calculated Initial Price</th>
                            <th>Price</th>
                            <th>Calculated Price</th>
                            <th>Total</th>
                            <th>Percentage Change</th>
                            <th>Status</th>
                            <th>Market Condition</th>
                            <th>Comments</th>
                            <th>Created On</th>
                            <th>Confirmed On</th>
                            <th>ExChange Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="record.tbAudits && record.tbAudits.length > 0">
                            <tr
                              v-for="audit in record.tbAudits.slice(record.meta.offset, record.meta.offset + record.meta.limit)"
                              :key="audit.ID">
                              <td>{{ audit.ID }}</td>
                              <td>{{ audit.OrderID }}</td>
                              <td>{{ audit.BaseCurrency }}</td>
                              <td>{{ audit.QuoteCurrency }}</td>
                              <td>{{ audit.Action }}</td>
                              <td class="no-wrap">{{ audit.Amount + ' ' + audit.BaseCurrency }}</td>
                              <td class="no-wrap">{{ audit.InitialPrice + ' ' + audit.QuoteCurrency }}</td>
                              <td class="no-wrap">
                                <span v-if="audit.Action === 'buy'">
                                  {{ audit.CalculatedInitialPrice + ' ' + currency(audit) }}
                                </span>
                              </td>
                              <td class="no-wrap">{{ audit.Price + ' ' + audit.QuoteCurrency }}</td>
                              <td class="no-wrap">
                                <span v-if="audit.Action === 'buy'">
                                  {{ audit.CalculatedPrice + ' ' + currency(audit) }}
                                </span>
                              </td>
                              <td class="no-wrap">{{ audit.Total + ' ' + audit.QuoteCurrency }}</td>
                              <td>
                                <span :class="`label ${audit.PercentageChange < 0 ? 'label-danger' : 'label-success'}`">
                                  {{ audit.PercentageChange + '%' }}
                                </span>
                              </td>
                              <td>{{ audit.Status }}</td>
                              <td>{{ audit.MarketCondition }}</td>
                              <td>{{ audit.Comments }}</td>
                              <td class="no-wrap">{{ audit.CreatedOn }}</td>
                              <td class="no-wrap">{{ audit.ConfirmedOn }}</td>
                              <td class="no-wrap">{{ audit.ExchangeDate }}</td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                      <template v-if="record.tbAudits && record.tbAudits.length > 0">
                        <pagination :meta="record.meta" @pageChange="(page) => updateFoldPage(page, index)">
                        </pagination>
                      </template>
                    </td>
                  </tr>
                </template>
              </template>
            </template>
            <template v-else>
              <tr>
                <td colspan="24">No record found!</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div v-if="meta && meta.total > 0">
        <pagination :meta="meta" @pageChange="updatePage"></pagination>
      </div>
    </div>
    <div v-else>
      <Loader />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loader from "@/components/partials/Loader";
import Pagination from "@/components/Pagination";

export default {
  name: "Bots",
  components: {
    Loader,
    Pagination,
  },
  data() {
    return {
      keyword: "",
      algo: "",
      sortKey: "ID",
      order: "desc",
      isLoading: false,
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      records: [],
      statuses: [
        {
          value: "pending",
          label: "Pending",
          selected: true,
        },
        {
          value: "running",
          label: "Running",
          selected: true,
        },
        {
          value: "paused",
          label: "Paused",
          selected: true,
        },
        {
          value: "completed",
          label: "Completed",
          selected: false,
        },
        {
          value: "aborted",
          label: "Aborted",
          selected: false,
        },
        {
          value: "cancelled",
          label: "Cancelled",
          selected: false,
        },
      ],
      allStatuses: false,
      webSocket: null,
      isCloseSocket: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "jwtToken",
    ]),
  },
  watch: {
    algo(val) {
      this.search();
    },
    statuses: {
      handler(newVal) {
        this.search();
      },
      deep: true,
    },
    allStatuses(newVal) {
      this.statuses = this.statuses.map(s => {
        return {
          ...s,
          selected: newVal,
        };
      });
      this.search();
    },
  },
  methods: {
    currency(record) {
      return record.Action === "sell" ? record.QuoteCurrency : record.BaseCurrency;
    },
    updateFoldPage(page, index) {
      const record = this.records[index];
      this.records.splice(index, 1, {
        ...record,
        meta: {
          ...record.meta,
          page,
          offset: (page - 1) * record.meta.limit,
        },
      });
    },
    updatePage(page) {
      this.getPaginatedTradeBots(page);
    },
    searchOnPaste(e) {
      if (e.code === "KeyV" && e.ctrlKey) {
        setTimeout(() => {
          this.search();
        }, 50);
      }
    },
    searchThreeChar(e) {
      if (this.keyword.trim().length >= 3) {
        setTimeout(() => {
          this.search();
        }, 50);
      } else if (this.keyword.trim() === "") {
        this.search();
      }
    },
    search() {
      this.getPaginatedTradeBots(1, false);
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.getPaginatedTradeBots(1, false);
    },
    getPaginatedTradeBots(page = 1, isLoader = true) {
      if (isLoader) {
        this.isLoading = true;
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListAllBots`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          limit: this.meta.limit,
          order: this.sortKey,
          orderBy: this.order,
          keyword: this.keyword ? this.keyword.toString().trim() : "",
          algo: this.algo,
          status: this.statuses.filter(s => s.selected).map(s => s.value).join(","),
        },
      }).then(response => {
        this.isLoading = false;
        this.meta = response.data.data.meta;
        this.records = response.data.data.records;
        if (this.records.length) {
          this.records = this.records.map(r => {
            const meta = {
              total: r.tbAudits ? r.tbAudits.length : 0,
              limit: 15,
              page: 1,
              offset: 0,
            };
            return {
              ...r,
              isExpanded: false,
              meta,
            };
          });
        }
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("", error.data.message, "error");
      });
    },
    generateStatusLabel(key, status) {
      let label = "";
      switch (key) {
        case "Immediate":
          label = status ? "Yes" : "No";
          break;
      }
      return label;
    },
    generateStatusClass(key, status) {
      let lblClass = "";
      switch (key) {
        case "Immediate":
          lblClass = status ? "label-success" : "label-danger";
          break;
      }
      return lblClass;
    },
    formatValue(status, key) {
      const formattedStatus = this.generateStatusLabel(key, status);
      const lblClass = this.generateStatusClass(key, status);
      return "<label class=\"label " + lblClass + " pointer\">" + formattedStatus + "</label>";
    },
    cancelBot(record) {
      const index = this.records.indexOf(record);
      const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
      this.$swal.fire({
        title: "Cancel Bot",
        text: "Are you sure you want to cancel this bot?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          this.$http.get(`${apiBaseUrl}/TradeBot/bot/cancel`, {
            params: {
              BotID: record.ID,
              MerchantID: record.MerchantID,
              APIKey: record.APIKey,
              key: "vMwdgOD7N2FT9hgh4i32Gfwn4QQCQ4IE",
            },
          }).then(response => {
            // update status
            if (response.status === 200) {
              if (index !== -1) {
                const statusList = this.statuses.filter(s => s.selected).map(s => s.value);
                if (statusList.indexOf("cancelled") !== -1) {
                  this.records.splice(index, 1, {
                    ...record,
                    Status: "Cancelled",
                  });
                } else {
                  this.records.splice(index, 1);
                }
              }
            }
          }).catch(_error => {
          });
        }
      });
    },
    pauseBot(bot) {
      const index = this._.findIndex(this.records, { ID: bot.ID });
      const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
      this.$swal.fire({
        title: "Pause Bot",
        text: "Are you sure you want to pause this bot?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          this.$http.get(`${apiBaseUrl}/TradeBot/bot/pause`, {
            params: {
              BotID: bot.ID,
              MerchantID: bot.MerchantID,
              APIKey: bot.APIKey,
              key: "vMwdgOD7N2FT9hgh4i32Gfwn4QQCQ4IE",
            },
          }).then(response => {
            this.$toast.fire("", response.data.reason, "success");
            // update status
            if (response.status === 200) {
              if (index !== -1) {
                const statusList = this.statuses.filter(s => s.selected).map(s => s.value);
                if (statusList.indexOf("paused") !== -1) {
                  const record = this.records[index];
                  this.records.splice(index, 1, {
                    ...record,
                    Status: "paused",
                  });
                } else {
                  this.records.splice(index, 1);
                }
              }
            }
          }).catch(error => {
            this.$toast.fire("", error.data.reason, "error");
          });
        }
      });
    },
    resumeBot(bot) {
      const index = this._.findIndex(this.records, { ID: bot.ID });
      const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
      this.$swal.fire({
        title: "Resume Bot",
        text: "Are you sure you want to resume this bot?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          this.$http.get(`${apiBaseUrl}/TradeBot/bot/resume`, {
            params: {
              BotID: bot.ID,
              MerchantID: bot.MerchantID,
              APIKey: bot.APIKey,
              key: "vMwdgOD7N2FT9hgh4i32Gfwn4QQCQ4IE",
            },
          }).then(response => {
            this.$toast.fire("", response.data.reason, "success");
            // update status
            if (response.status === 200) {
              if (index !== -1) {
                const statusList = this.statuses.filter(s => s.selected).map(s => s.value);
                if (statusList.indexOf("running") !== -1) {
                  const record = this.records[index];
                  this.records.splice(index, 1, {
                    ...record,
                    Status: "running",
                  });
                } else {
                  this.records.splice(index, 1);
                }
              }
            }
          }).catch(error => {
            this.$toast.fire("", error.data.reason, "error");
          });
        }
      });
    },
    initSocket() {
      const self = this;
      this.webSocket = new WebSocket("wss://artemis.cointopay.com/TradeBot/tradebot");
      this.webSocket.onmessage = (evt) => {
        if (evt) {
          const data = JSON.parse(evt.data);
          this.search();
          if (["sell", "buy"].indexOf(data.Action.toLowerCase()) !== -1) {
            this.$toast.fire("", data.Action.toUpperCase() + ": Trade posted", "success");
          }
        }
      };
      this.webSocket.onclose = (evt) => {
        if (!self.isCloseSocket) {
          self.initSocket();
        }
      };
    },
  },
  beforeMount() {
    this.initSocket();
  },
  mounted() {
    this.getPaginatedTradeBots();
  },
  beforeDestroy() {
    if (this.webSocket) {
      this.isCloseSocket = true;
      this.webSocket.close();
    }
  },
};
</script>

<style scoped lang="scss">
.dropdown-menu {
  padding: 4px 8px;
  min-width: 220px;

  a {
    display: block;
    cursor: pointer;
    margin-bottom: 1px;

    label {
      margin: 0;
      padding: 2px 4px;
      cursor: pointer;
      color: #404040;
      font-weight: normal;
    }

    :hover {
      background-color: #efefef;
    }
  }
}
</style>
