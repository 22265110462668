<template>
  <div id="bsModalKuCoinData" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
    data-keyboard="false" @click="clickOverlay()" data-toggle="modal" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;
            </span>
          </button>
          <h4 id="bsModalLabel" class="modal-title text-capitalize">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <div v-if="action === 'ledgers'" class="text-right mb-8">
            <date-picker v-model="range.startDate" :clearable="false" :editable="false" :disabled-date="disabledDates"
              format="YYYY-MM-DD" value-type="format"></date-picker>
            <date-picker v-model="range.endDate" :clearable="false" :editable="false" class="ml-8"
              :disabled-date="disabledDates" format="YYYY-MM-DD" value-type="format"></date-picker>
            <button type="button" class="btn btn-flat btn-primary ml-8" @click="searchLedgers()">Search</button>
          </div>
          <template v-if="!isLoading">
            <template v-if="data">
              <div class="table-responsive">
                <table class="table table-condensed table-striped"
                  v-if="action === 'deposits' || action === 'withdrawals'">
                  <thead>
                    <tr>
                      <th>Created At</th>
                      <th>Amount</th>
                      <th>Address</th>
                      <th>Chain</th>
                      <th>Memo</th>
                      <th>Fee</th>
                      <th>Tx ID</th>
                      <th>Status</th>
                      <th>Remark</th>
                      <th>Updated At</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="data.items.length">
                      <tr v-for="(item, index) in data.items" :key="index">
                        <td class="no-wrap">{{ formatDT(item.createdAt) }}</td>
                        <td class="no-wrap">{{ item.amount + " " + item.currency }}</td>
                        <td>{{ item.address }}</td>
                        <td>{{ item.chain }}</td>
                        <td>{{ item.memo }}</td>
                        <td class="no-wrap">{{ item.fee + " " + item.currency }}</td>
                        <td>{{ item.walletTxId }}</td>
                        <td>{{ item.status }}</td>
                        <td>{{ item.remark }}</td>
                        <td class="no-wrap">{{ formatDT(item.updatedAt) }}</td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="10" class="text-center">No record found!</td>
                      </tr>
                    </template>
                  </tbody>
                </table>

                <table class="table table-condensed table-striped" v-if="action === 'ledgers'">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Amount</th>
                      <th>Fee</th>
                      <th>Balance</th>
                      <th>Account Type</th>
                      <th>Biz Type</th>
                      <th>Direction</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="data.items.length">
                      <tr v-for="(item, index) in paginatedItems" :key="index">
                        <td>{{ item.id }}</td>
                        <td class="no-wrap">{{ item.amount + " " + item.currency }}</td>
                        <td class="no-wrap">{{ item.fee + " " + item.currency }}</td>
                        <td class="no-wrap">{{ item.balance + " " + item.currency }}</td>
                        <td>{{ item.accountType }}</td>
                        <td>{{ item.bizType }}</td>
                        <td>{{ item.direction }}</td>
                        <td class="no-wrap">{{ formatDT(item.createdAt) }}</td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="8" class="text-center">No record found!</td>
                      </tr>
                    </template>
                  </tbody>
                </table>

                <table class="table table-condensed table-striped" v-if="action === 'balance'">
                  <thead>
                    <tr>
                      <th>Currency</th>
                      <th>Main Balance</th>
                      <th>Trade Balance</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="data.length">
                      <tr v-for="(item, index) in data" :key="index">
                        <td>{{ item.currency }}</td>
                        <td>{{ item.mainBalance }}</td>
                        <td>{{ item.tradeBalance }}</td>
                        <td>
                          <button type="button" @click="transferBalance(item)"
                            v-if="(item.mainBalance > 0 || item.tradeBalance > 0) && null === record.bot"
                            class="btn btn-primary btn-flat btn-sm">Transfer To PoolMaster</button>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="4" class="text-center">No record found!</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <pagination :meta="meta" @pageChange="updatePage"></pagination>
            </template>
            <template v-else>
              <p class="text-center">No record found!</p>
            </template>
          </template>
          <template v-else>
            <div class="text-center">
              <i class="fa fa-spin fa-circle-o-notch fa-5x"></i>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Pagination from "@/components/Pagination";

const moment = require("moment-timezone/builds/moment-timezone-with-data-1970-2030");

moment.tz.setDefault("Europe/Amsterdam");

export default {
  name: "KuCoinDataModal",
  components: {
    Pagination,
    DatePicker,
  },
  data() {
    return {
      isLoading: false,
      title: "",
      record: null,
      action: null,
      data: null,
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: 25,
      },
      currency: null,
      range: {
        startDate: this.tz().subtract(1, "days").format("YYYY-MM-DD"),
        endDate: this.tz().format("YYYY-MM-DD"),
      },
    };
  },
  computed: {
    paginatedItems() {
      if (this.action === "ledgers") {
        return this.data.items.slice(
          this.meta.offset,
          this.meta.offset + this.meta.limit,
        );
      } else {
        return this.data.items;
      }
    },
  },
  methods: {
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalKuCoinData").modal("hide");
    },
    formatDT(val) {
      const timestamp = Number(val);
      let date = new Date(timestamp);
      date = moment.utc(date, "YYYY-MM-DD HH:mm:ss").tz("Europe/Amsterdam");
      return date.format("YYYY-MM-DD hh:mm:ss A");
    },
    updatePage(page) {
      if (this.action === "ledgers") {
        const offset = (page - 1) * this.meta.limit;
        this.meta = {
          ...this.meta,
          page,
          offset,
        };
      } else {
        this.fetchData(page, false);
      }
    },
    disabledDates(date) {
      return date > new Date();
    },
    searchLedgers() {
      const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
      if (this.range.startDate > this.range.endDate) {
        this.$toast.fire("", "End cannot be smaller then start date", "error");
      } else {
        this.isLoading = true;
        const dates = this.getDatesBetween(this.range.startDate, this.range.endDate);
        const actions = [];
        dates.forEach(d => {
          actions.push(this.$http.get(`${apiBaseUrl}/kucoin/`, {
            params: {
              action: "ledgers",
              api_key: "jediojdfeoijdeoiej",
              kucoin_apikey: this.record.XChangeAPIKey,
              kucoin_apisecret: this.record.XChangeSecretKey,
              kucoin_passphrase: "kukel333kuuu_kucoinA",
              currency: this.currency,
              currentPage: 1,
              pageSize: 500,
              startAt: d,
            },
          }));
        });
        Promise.all(actions).then(response => {
          this.isLoading = false;
          this.data = {
            currentPage: 1,
            items: [],
            pageSize: 25,
            totalNum: 0,
            totalPage: 0,
          };
          response.forEach(r => {
            const d = r.data.data;
            this.data.items = this.data.items.concat(d.items);
          });
          this.data.items = this.data.items.map(i => {
            return {
              ...i,
              createdAt: Number(i.createdAt),
            };
          });
          this.data.items = this._.orderBy(this.data.items, "createdAt", "desc");
          this.meta = {
            total: this.data.items.length,
            page: this.data.currentPage,
            offset: (this.data.currentPage - 1) * 25,
            limit: 25,
          };
        }).catch(_error => {
          this.isLoading = false;
        });
      }
    },
    getDatesBetween(startDate, endDate) {
      const dates = [];
      endDate = new Date(endDate);
      endDate.setUTCHours(0, 0, 0, 0);
      const currentDate = new Date(startDate);
      currentDate.setUTCHours(0, 0, 0, 0);

      // eslint-disable-next-line no-unmodified-loop-condition
      while (currentDate <= endDate) {
        const d = new Date(currentDate);
        d.setUTCHours(0, 0, 0, 0);
        dates.push(d.getTime());
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    },
    fetchData(page = 1, isLoader = true) {
      if (isLoader) {
        this.isLoading = true;
      }
      let params = {
        action: this.action,
        api_key: "jediojdfeoijdeoiej",
        kucoin_apikey: this.record.XChangeAPIKey,
        kucoin_apisecret: this.record.XChangeSecretKey,
        kucoin_passphrase: "kukel333kuuu_kucoinA",
        currency: this.currency,
      };
      if (this.action !== "balance") {
        params = {
          ...params,
          currentPage: page,
          pageSize: 25,
        };
      }
      const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
      this.$http.get(`${apiBaseUrl}/kucoin/`, {
        params,
      }).then(response => {
        this.isLoading = false;
        if (this.action === "balance") {
          const formattedBalancesList = [];
          const records = response.data.data;
          records.forEach(r => {
            const index = this._.findIndex(formattedBalancesList, { currency: r.currency });
            if (index !== -1) {
              let record = formattedBalancesList[index];
              record = {
                ...record,
                mainBalance: r.type === "main" ? r.available : record.mainBalance,
                tradeBalance: r.type === "trade" ? r.available : record.tradeBalance,
              };
              formattedBalancesList.splice(index, 1, record);
            } else {
              formattedBalancesList.push({
                currency: r.currency,
                mainBalance: r.type === "main" ? r.available : 0,
                tradeBalance: r.type === "trade" ? r.available : 0,
              });
            }
          });
          this.data = formattedBalancesList;
        } else {
          this.data = response.data.data;
          if (this.data) {
            this.meta = {
              total: this.data.totalNum,
              page: this.data.currentPage,
              offset: (this.data.currentPage - 1) * this.data.pageSize,
              limit: this.data.pageSize,
            };
          }
        }
      }).catch(_error => {
        this.isLoading = false;
      });
    },
    transferBalance(item) {
      const apiBaseUrl = process.env.VUE_APP_ARTEMIS_API_URL;
      this.$swal.fire({
        title: "Transfer Balance",
        text: "Are you sure you want to transfer balance to PoolMaster?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          if (item.tradeBalance > 0) {
            // Transfer main balance to poolmaster
            this.$http.get(`${apiBaseUrl}/kucoin-broker`, {
              params: {
                action: "transfer",
                key: "vMwdgOD7N2FT9hgh4i32Gfwn4QQCQ4IE",
                accountType: "trade",
                specialAccountType: "main",
                currency: item.currency,
                amount: item.tradeBalance,
                uid: this.record.kc_broker_uid,
                direction: "in",
              },
            }).then(response => {
              console.log(response.data.data);
            }).catch(_error => {
            });
          }
          if (item.mainBalance > 0) {
            // Transfer main balance to poolmaster
            this.$http.get(`${apiBaseUrl}/kucoin-broker`, {
              params: {
                action: "transfer",
                key: "vMwdgOD7N2FT9hgh4i32Gfwn4QQCQ4IE",
                accountType: "main",
                specialAccountType: "main",
                currency: item.currency,
                amount: item.mainBalance,
                uid: this.record.kc_broker_uid,
                direction: "in",
              },
            }).then(response => {
              console.log(response.data.data);
            }).catch(_error => {
            });
          }
          this.fetchData(1, false);
        }
      });
    },
  },
  mounted() {
    this.$bus.$on("KuCoinDataModal", (args) => {
      this.action = args.action;
      this.record = args.record;
      this.title = args.action;
      this.currency = args.currency;
      if (this.action === "ledgers") {
        this.searchLedgers();
      } else {
        this.fetchData(1);
      }
      $("#bsModalKuCoinData").modal("show");
    });
  },
};
</script>

<style scoped></style>
