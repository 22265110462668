<template>
  <div class="box bt-0">
    <va-tabs ref="tabs" :padding="'padding: 10px 15px 20px'" @selectedTab="selectedTab">
      <div id="bots" slot="content" data-title="Trade Bots" class="active">
        <bots v-if="tab === 'bots'"></bots>
      </div>
      <div id="sub-borker-accounts" slot="content" data-title="Sub Broker Accounts">
        <sub-broker-accounts v-if="tab === 'sub-borker-accounts'"></sub-broker-accounts>
      </div>
    </va-tabs>
  </div>
</template>

<script>
import VaTabs from "@/components/VATabs";
import SubBrokerAccounts from "@/components/tradebot/SubBrokerAccounts";
import Bots from "@/components/tradebot/Bots";

export default {
  name: "TradeBots",
  components: {
    VaTabs,
    SubBrokerAccounts,
    Bots,
  },
  data() {
    return {
      tab: "bots",
    };
  },
  watch: {
    $route: {
      handler(to) {
        if (to.hash) {
          setTimeout(() => {
            let hash = to.hash;
            hash = hash.split("?");
            this.tab = hash[0].replace("#", "");
            $("[href=\"#" + this.tab + "\"]").tab("show");
          }, 500);
        }
      },
      immediate: true,
    },
  },
  methods: {
    selectedTab(id) {
      this.tab = id;
    },
  },
  beforeMount() {
    if (this.$route.hash) {
      this.tab = this.$route.hash.replace("#", "");
      setTimeout(() => {
        $("[href=\"#" + this.tab + "\"]").tab("show");
      }, 150);
    }
  },
};
</script>
